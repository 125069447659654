// src/HighSchool_advice.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import './App.css'; // Ensure App.css is imported
import aboutUsImage from './assets/clean_logo.JPG'; // Import the image

function MidcareerAdvice() {
  const navigate = useNavigate();
  // Define the handleClick function to navigate to different paths
  const handleClick = (path) => {
    navigate(path);
  };
  window.scrollTo(0, 0);
  return (
    <div className="container">
      <div className="title-container">
      <h1>Advice for a Career Change</h1>
      <img src={aboutUsImage} alt="About Us" className="about-us-image" />
      <p>If you're a UK worker contemplating a career change after several years in your current role, it’s natural to feel both excited and uncertain. Making a successful transition involves careful consideration of your skills, experiences, and personal interests. Begin by reflecting on what aspects of your current job you enjoy and what you find less fulfilling. This self-assessment will help you identify transferable skills and areas of passion that can guide you towards a new career path. Consider how your current skills and experiences could be leveraged in a different industry or role. For example, if you’ve been in project management, you might find that your organisational and leadership skills are highly valued in sectors like consulting or operations.</p>
      <button onClick={() => handleClick('/Midcareer_test')}className="button-mc-test">Take the Quiz </button> 
      <p>Next, think about how your educational background and past work experience align with your new career interests. If you have a degree in finance and are considering a shift to accounting or financial analysis, this academic foundation is a strong asset. To further enhance your qualifications and make yourself more competitive, look into industry-specific certifications or courses. In the UK, for instance, pursuing a qualification from a recognised body like the Association of Chartered Certified Accountants (ACCA) can be beneficial. These qualifications not only validate your expertise but also provide a structured path to learning any additional skills required for the new role.</p>
      <button onClick={() => handleClick('/Midcareer_test')}className="button-mc-test">Take the Quiz </button> 
      <p>Furthermore, it’s wise to engage in some research about the industries or roles you’re interested in. Informational interviews with professionals in those fields, online courses, and industry webinars can provide valuable insights into the day-to-day responsibilities and the skills that are in demand. Networking is another crucial step; joining relevant professional groups or forums can help you connect with others who have made similar transitions and learn from their experiences.</p>
      <button onClick={() => handleClick('/Midcareer_test')}className="button-mc-test">Take the Quiz </button> 
      <p>If you’re considering additional training, look for courses that offer practical experience and are recognised within the industry. For example, if you’re moving into IT, certifications or specific programming courses might be worth exploring. Always check the credibility of the training provider and ensure that the qualification will be valued by prospective employers.</p>
      <button onClick={() => handleClick('/Midcareer_test')}className="button-mc-test">Take the Quiz </button> 
      <p>Lastly, be patient and open-minded during this process. Career transitions can take time and may involve starting at a different level than you’re used to, but with careful planning and preparation, they can lead to fulfilling new opportunities. Keep a positive outlook and remember that each step you take brings you closer to finding a role that matches your skills and passions.</p>
        <button onClick={() => handleClick('/')}className="button-home">Home Page </button>  
      </div>

      <div className="footer">
        <h3>____________________________________________________________</h3>
        <ul className="footer-links">
          <li onClick={() => handleClick('/AboutUs')}>About Us</li>
          <li onClick={() => handleClick('/Contact')}>Contact</li>
          <li onClick={() => handleClick('/Terms-and-conditions')}>Terms & Conditions</li>
        </ul>
        <p>&copy; 2024 www.studentcareerquiz.co.uk. All rights reserved.</p>
      </div>
      
    </div>
  );
}

export default MidcareerAdvice;



